define('ember-paper/components/paper-toaster', ['exports', 'ember-paper/templates/components/paper-toaster'], function (exports, _paperToaster) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _paperToaster.default,
    tagName: '',
    paperToaster: service(),
    activeToast: reads('paperToaster.activeToast'),

    onClose: function onClose(toast) {
      this.get('paperToaster').cancelToast(toast);
    }
  });
});