define('ember-paper/components/paper-autocomplete-trigger', ['exports', 'ember-paper/templates/components/paper-autocomplete-trigger'], function (exports, _paperAutocompleteTrigger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var not = Ember.computed.not;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _paperAutocompleteTrigger.default,
    tagName: 'md-autocomplete-wrap',
    classNameBindings: ['noLabel:md-whiteframe-z1', 'select.isOpen:md-menu-showing', 'showingClearButton:md-show-clear-button'],

    noLabel: not('extra.label'),
    showingClearButton: computed('allowClear', 'disabled', 'resetButtonDestroyed', function () {
      // make room for clear button:
      // - if we're enabled
      // - or if we're disabled but the button still wasn't destroyed
      return this.get('allowClear') && (!this.get('disabled') || this.get('disabled') && !this.get('resetButtonDestroyed'));
    }),

    text: computed('select.{searchText,selected}', function () {
      var selected = this.get('select.selected');
      if (selected) {
        return this.getSelectedAsText();
      }
      return this.get('select.searchText');
    }).readOnly(),

    // Lifecycle hooks
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var prevDisabled = this.get('_prevDisabled');
      var disabled = this.get('disabled');
      if (prevDisabled && !disabled) {
        this.set('resetButtonDestroyed', false);
      }

      this.setProperties({
        _prevDisabled: disabled
      });
    },


    // Actions
    actions: {
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      },
      clear: function clear(e) {
        e.stopPropagation();
        if (this.get('onClear')) {
          this.get('onClear')();
        } else {
          this.get('select').actions.select(null);
          this.get('onInput')({ target: { value: '' } });
        }
        this.get('onFocus')(e);
        this.$('input').focus();
      },
      handleKeydown: function handleKeydown(e) {
        var isLetter = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32; // Keys 0-9, a-z or SPACE
        var isSpecialKeyWhileClosed = !isLetter && !this.get('select.isOpen') && [13, 27, 38, 40].indexOf(e.keyCode) > -1;
        if (isLetter || isSpecialKeyWhileClosed) {
          e.stopPropagation();
        }
      },
      handleInputLocal: function handleInputLocal(e) {
        // If something is already selected when the user types, it should clear selection
        if (this.get('select.selected')) {
          this.get('select').actions.select(null);
        }
        this.get('onInput')(e.target ? e : { target: { value: e } });
      },
      resetButtonDestroyed: function resetButtonDestroyed() {
        if (this.get('disabled')) {
          this.set('resetButtonDestroyed', true);
        }
      }
    },
    // Methods
    getSelectedAsText: function getSelectedAsText() {
      var labelPath = this.get('extra.labelPath');
      if (labelPath) {
        return this.get('select.selected.' + labelPath);
      } else {
        return this.get('select.selected');
      }
    }
  });
});